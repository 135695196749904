import React from 'react';

import MainNavigation from '../modules/MainNavigation';

import HeadOverride from '../components/HeadOverride';
import ContentWrapper from '../components/ContentWrapper';
import CompanyInfo from '../components/CompanyInfo';

export default ({ location, pageContext }) => (
  <>
    <HeadOverride robots="noindex" canonical={location.origin} />

    <ContentWrapper withBorders>
      <CompanyInfo />

      <MainNavigation
        path={location.pathname}
        selectedItems={pageContext.selectedItems}
      />
    </ContentWrapper>
  </>
);
